export const translate = (
  key: string,
  languageMap: Map<string, string>,
  payload?: Record<string, any> | Array<any>
) => {
  let realKey = languageMap.get(key)
  if (!realKey) {
    realKey = key
  }
  return window.i18n?.t(realKey, payload)
}
