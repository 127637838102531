<template>
  <div class="container">
    <!-- @selection-change="handleSelectChange" -->
    <el-table
      :data="tableData"
      ref="elTableRef"
      empty-text
      v-bind="$attrs"
      :highlight-current-row="true"
      :cell-style="cellStyle"
      :row-style="rowStyle"
      :header-cell-style="headerCellStyle"
      :header-row-style="headerRowStyle"
      @row-click="handleRowClick"
      @cell-click="handleCellClick"
      @select="handleSelectCheckbox"
      @select-all="handleSelctAll"
      size="mini"
    >
      <el-table-column
        v-if="showSelection && selectionDirection == 'top'"
        type="selection"
        width="80"
        align="center"
      ></el-table-column>
      <el-table-column
        v-if="showIndexColumn"
        :label="indexColumnLabel"
        type="index"
        width="80"
        align="center"
      >
      </el-table-column>
      <template v-for="propItem in propList">
        <el-table-column
          v-bind="propItem"
          :label="translateLang(propItem.label)"
          :key="propItem.prop"
          align="center"
          show-overflow-tooltip=""
        >
          <template slot-scope="scope">
            <slot :name="propItem.slotName" :row="scope.row">
              {{ scope.row[propItem.prop] }}
            </slot>
          </template>
        </el-table-column>
      </template>
      <el-table-column
        v-if="showSelection && selectionDirection == 'bottom'"
        type="selection"
        width="80"
        align="center"
      ></el-table-column>
    </el-table>
  </div>
</template>

<script>
import { translate } from '@/util/commonFun'

export default {
  props: {
    tableData: {
      type: Array,
      default: () => [],
      required: true,
    },
    showSelection: {
      type: Boolean,
      default: false,
    },
    selectionDirection: {
      default: 'top',
    },
    showIndexColumn: {
      type: Boolean,
      default: true,
    },
    indexColumnLabel: {
      type: String,
      default: '编号',
    },
    propList: {
      type: Array,
      default: () => [],
      required: true,
    },

    /** 样式 */
    cellStyle: {
      type: Object,
      default: () => ({}),
    },
    rowStyle: {
      type: Object,
      default: () => ({
        height: '44px',
        'border-top': '1px solid #EEEEEE',
        'font-size': '14px',
        color: '#000000',
        'font-weight': '400',
      }),
    },
    headerCellStyle: {
      type: Object,
      default: () => ({
        background: '#fafafa',
      }),
    },
    headerRowStyle: {
      type: Object,
      default: () => ({
        height: '50px',
        'font-size': '14px',
        color: '#000000',
        'font-weight': '500',
        'border-bottom': 'none',
      }),
    },
    deleteIdList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      currentRow: {},
      selectList: [],
    }
  },
  watch: {
    tableData: {
      handler() {
        this.$nextTick(() => {
          this.$refs['elTableRef'].doLayout()
          // 表格勾选状态的赋值 遍历tableData的值
          this.tableData.map((item) => {
            // 判断item.id是否在selectList里面
            if (this.selectList.map((item) => item.id).includes(item.id)) {
              this.$refs['elTableRef'].toggleRowSelection(item, true)
            }
          })
        })
      },
      deep: true,
    },
    deleteIdList: {
      handler(newVlaue) {
        // 删除ids里面的数据
        this.selectList = this.selectList.filter(
          (item) => !newVlaue.includes(item.id)
        )
      },
    },
  },
  methods: {
    translateLang(key) {
      return translate(key)
    },
    handleSelectCheckbox(selection, row) {
      // 将选中的数据加到数组里面去 判断row的数据是否在selection里面
      if (selection.map((item) => item.id).includes(row.id)) {
        this.selectList.push(row)
      } else {
        // 不存在里面 将元素从数组里面删除
        this.selectList.map((item, index) => {
          if (item.id === row.id) {
            // 删除这个元素
            this.selectList.splice(index, 1)
          }
        })
      }
      this.$emit('selectChange', this.selectList)
    },
    // handleSelectChange(val) {
    //   this.$emit('selectChange', val)
    // },
    handleSelctAll(selection) {
      let selectListIds = this.selectList.map((item) => item.id)
      this.tableData.map((item) => {
        // 全选
        if (selection.length) {
          if (!selectListIds.includes(item.id)) {
            this.selectList.push(item)
          }
        } else {
          if (selectListIds.includes(item.id)) {
            this.selectList.map((li, index) => {
              if (li.id === item.id) {
                this.selectList.splice(index, 1)
              }
            })
          }
        }
      })
      this.$emit('selectChange', this.selectList)
    },
    handleRowClick(row, column, event) {
      this.$emit('rowClick', row, column, event)
      this.currentRow = row
    },
    handleCellClick(row, column, cell, event) {
      this.$emit('cellClick', row, column, cell, event)
    },
  },
}
</script>

<style lang="less" scoped>
.container {
  /deep/.el-table {
    .el-table__body tr.current-row:hover > td {
      background: rgb(245, 247, 250);
    }
    .el-table__body tr.current-row > td {
      background: rgba(24, 168, 199, 0.2);
      overflow: hidden;
    }
  }
}
</style>
