<template>
  <div class="container" v-loading="tableLoading">
    <base-table
      v-bind="tableConfig"
      :tableData="data"
      :deleteIdList="deleteIdList"
      @rowClick="rowClick"
      @cellClick="cellClick"
      @selectChange="selectChange"
    >
      <!-- 插槽有交互效果 -->

      <template
        v-for="interactionItem in interactionPropSlots"
        #[interactionItem.slotName]="scope"
      >
        <div :key="interactionItem.prop" class="inputClick">
          <!-- input start-->

          <template
            v-if="
              interactionItem.interactionType == 'input' ||
              interactionItem.interactionType == 'textarea'
            "
          >
            <!-- 选中的格子进入编辑 -->
            <el-input
              :type="interactionItem.interactionType"
              v-model="scope.row[interactionItem.prop]"
              autosize
              resize="none"
              size="mini"
              ref="elInteractionRef"
              @blur="inputBlur(scope.row)"
              v-if="
                scope.row.uniqueSign === activeIdx &&
                activeProp === interactionItem.prop
              "
              @input="emitInput(scope.row, interactionItem.prop)"
              :id="`page_table_${scope.row.index}_${interactionItem.prop}_input`"
            >
            </el-input>

            <!-- 门套机替换数据显示 -->
            <span
              v-else-if="dataFrom"
              :id="`page_table_${scope.row.index}_${interactionItem.prop}`"
              v-html="scope.row[interactionItem.prop].replace(/\n/g, '<br/>')"
            >
            </span>
            <span
              v-else
              :id="`page_table_${scope.row.index}_${interactionItem.prop}`"
              >{{ scope.row[interactionItem.prop] }}</span
            >
          </template>

          <!-- input end-->

          <!-- select start-->

          <template v-if="interactionItem.interactionType == 'select'">
            <el-select
              size="mini"
              v-model="scope.row[interactionItem.prop]"
              placeholder="请选择"
              @change="emitSelect(scope.row, interactionItem.prop)"
              :id="`page_table_${scope.row.index}_select`"
            >
              <el-option
                v-for="(option, index) in interactionItem.selectOptions"
                :key="option.value"
                :label="translateLang(option.label)"
                :value="option.value"
                :id="`page_table_${scope.row.index}_option_${index}`"
              >
              </el-option>
            </el-select>
          </template>

          <!-- select end-->
        </div>
      </template>

      <!-- 其他的插槽 -->

      <template v-for="item in otherPropSlots" #[item.slotName]="scope">
        <div :key="item.prop">
          <slot :name="item.slotName" :row="scope.row">
            <span :id="`page_table_${scope.row.index}_${item.prop}`">{{
              scope.row[item.prop]
            }}</span>
          </slot>
        </div>
      </template>
    </base-table>
    <!-- size-change每页条数发生变化时触发 current-change当前页发生变化时触发 -->
    <el-pagination
      v-if="pagination.total != 0"
      v-bind="pagination"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :hide-on-single-page="false"
      :current-page="page.page"
      :page-size="page.limit"
    >
    </el-pagination>
  </div>
</template>

<script>
import baseTable from '@/baseui/table'
import { translate } from '@/util/commonFun'

export default {
  components: { baseTable },
  props: {
    tableConfig: {
      type: Object,
      required: true,
    },
    data: {
      type: Array,
      default: () => [],
    },
    rules: {
      type: Object,
      default: () => ({}),
    },
    pagination: {
      type: Object,
      default: () => ({
        total: 0,
      }),
    },
    page: {
      type: Object,
      default: () => ({
        limit: 10,
        page: 1,
      }),
    },
    tableLoading: {
      type: Boolean,
      default: false,
    },
    dataFrom: {
      type: String,
      default: '',
    },
    deleteIdList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      activeIdx: -1,
      activeProp: '',
      selectRow: {},
      // 是否可以显示其他的输入框
      isCancelActive: true,
      // 上一次的props的data
      oldData: [],
      // 上一次的props的长度
      length: 0,
    }
  },
  watch: {
    data: {
      handler(n) {
        this.$emit('update:data', n)
        // 保存之前的data，当data对象改变 长度改变，则重新添加唯一标识
        if (n != this.oldData || this.length != n.length) {
          this.data.forEach((item, idx) => {
            item.uniqueSign = idx
          })
          this.oldData = JSON.parse(JSON.stringify(n))
          this.length = n.length
        }
      },
      deep: true,
    },
  },
  computed: {
    /** 过滤出，不是需要input输入框交互的插槽 */
    otherPropSlots() {
      const slots = this.tableConfig.propList.filter((prop) => {
        if (!prop.interactionType) {
          return true
        }
      })
      return slots
    },
    /** 需要交互的插槽 */
    interactionPropSlots() {
      const slots = this.tableConfig.propList.filter((prop) => {
        if (prop.interactionType) {
          return true
        }
      })
      return slots
    },
  },
  mounted() {
    /** 给每一个item加上一个唯一的标记用来判断选中 */
    this.data.forEach((item, idx) => {
      item.uniqueSign = idx
    })
  },
  methods: {
    rowClick(row, column, event) {
      this.selectRow = row
      this.$emit('clickRow', row, column, event)
    },
    cellClick(row, column) {
      if (this.isCancelActive) {
        this.activeIdx = row.uniqueSign
        this.activeProp = column.property
        const find = this.interactionPropSlots.find(
          (item) => item.prop === column.property
        )
        if (find) {
          this.$nextTick(() => {
            this.$refs['elInteractionRef'] &&
              this.$refs['elInteractionRef'][0].focus()
          })
        }
      }
      this.$emit('clickColumn', row, column)
    },
    // 表格多选/单选 当前这种形式只能选择一个分页下的数据，切换分页之后之前的选择就被清空了
    selectChange(select) {
      this.$emit('selectChange', select)
    },
    inputBlur(row) {
      // 取消焦点前，需要通过验证
      const { flag, msg } = this.rules.input
        ? this.rules.input(row, this.data)
        : { flag: true }
      this.isCancelActive = flag
      if (this.isCancelActive) {
        this.activeIdx = -1
        this.activeProp = ''
        // 某一行数据发送改变，发出事件
        this.$emit('inputBlur', row)
      } else {
        this.$nextTick(() => {
          this.$refs['elInteractionRef'] &&
            this.$refs['elInteractionRef'][0]?.focus()
        })
        this.$message.error(msg)
      }
    },
    /**
     * @param { string } prop 外部判断是否需要对这个字段进行输入限制
     */
    emitInput(row, prop) {
      this.$emit('tableInputChange', row, prop)
    },
    // select组件选中
    emitSelect(row, props) {
      this.$emit('tableSelectChange', row, props)
    },
    handleSizeChange(limit) {
      this.$emit('pageChange', { limit, page: 1 })
    },
    handleCurrentChange(page) {
      this.$emit('pageChange', { ...this.page, page })
    },
    translateLang(key) {
      return translate(key)
    },
  },
}
</script>

<style lang="less" scoped>
.container {
  .operation-btn {
    padding-top: 48px;
    box-sizing: border-box;
  }
}
.el-pagination--small {
  display: flex;
  align-items: center;
  /deep/.el-pager li {
    vertical-align: middle;
  }
}
.el-pagination--small /deep/ .el-pagination__sizes {
  height: 26px;
  .el-input__inner {
    height: 26px;
  }
}
</style>
